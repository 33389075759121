.image-upload-wrap,
.image-upload-wrap-error,
.inner-image-upload-wrap {
  border-radius: 8px;
  border: 1px solid #dadada;
  height: 83px;
  padding: 5.5px;
  @apply cursor-pointer;
}

.inner-image-upload-wrap {
  margin-top: 20px;
  border-radius: 10px;
  background: #f1f1f1;
  border: none;
  border-radius: 10px;
  flex-shrink: 0;
}

.image-uploaded {
  background: none;
  border: 1px solid #dadada;
  padding: 0;
  width: 83px;
  height: 83px;
  border-radius: 8px;
}

.icon-cross {
  position: absolute;
  top: -9px;
  right: -9px;
  z-index: 1;
}

.file-upload-input {
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
  @apply cursor-pointer;
}

.icon-camera {
  position: relative;
  top: -102%;
}
