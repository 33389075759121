.form-label {
  @apply text-[12px] text-gray-500 font-bold tracking-[0.3px];
}

.form-search-input {
  @apply flex h-[45px] w-full py-[11px] px-14 placeholder:text-gray-400 placeholder:tracking-[0.5px] placeholder:font-light text-[12px] text-gray-700 border-[1px] rounded-[8px] outline-none transition duration-150 ease-in-out bg-[#FCFDFE] tracking-[0.3px] border-[#F0F1F7] focus:border-primary-default;
}

.form-search-input-right-icon {
  @apply absolute top-0 right-0 h-[45px] w-14 flex justify-center items-center;
}

.form-search-input-left-icon {
  @apply absolute top-0 left-0 h-[45px] w-14 flex justify-center items-center;
}

.card-search-result {
  @apply border-[#F0F1F7] shadow-xl invisible group-focus-within:visible border-[1px] rounded-lg mt-1 bg-white max-h-48 overflow-y-auto absolute w-full;
}

.list-search-result {
  @apply cursor-pointer text-sm transition-all duration-200 px-4 py-2 hover:bg-primary-default hover:text-white;
}
