.skeleton-square {
  @apply animate-pulse bg-slate-200 rounded-lg;
}

.skeleton-circle {
  @apply animate-pulse bg-slate-200 rounded-full;
}

.skeleton-triangle {
  background: linear-gradient(
    to bottom right,
    #fff 0%,
    #fff 50%,
    #f8f9fd 50%,
    #f8f9fd 100%
  );
  transform: rotate(225deg);
  @apply animate-pulse rotate-[225deg] bg-slate-200;
}
