.wrapper {
  @apply min-h-[calc(100%-68px)] transition-all duration-200 bg-white w-full cursor-pointer hover:shadow-xl;
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
}

.card-header {
  @apply pt-6 px-8 w-full;
}

.hr {
  @apply border-[1px] my-5;
}

.card-footer {
  @apply pb-6 px-8 w-full;
}
