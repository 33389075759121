.form-label {
  @apply text-[12px] text-gray-500 font-bold tracking-[0.3px];
}

.form-input,
.form-input-error,
.form-input-with-icon-right,
.form-input-with-icon-left,
.form-input-with-icon-right-error,
.form-input-with-icon-left-error {
  @apply flex h-[45px] py-[11px] px-[18px] placeholder:text-gray-400 placeholder:tracking-[0.5px] placeholder:font-light text-[12px] text-gray-700 border-[1px] rounded-[8px] outline-none transition duration-150 ease-in-out bg-[#FCFDFE] tracking-[0.3px];
}

.form-input,
.form-input-with-icon-right,
.form-input-with-icon-left {
  @apply border-[#F0F1F7] focus:border-primary-default;
}

.form-input-error,
.form-input-with-icon-right-error,
.form-input-with-icon-left-error {
  @apply border-danger-default;
}

.form-input-with-icon-left,
.form-input-with-icon-left-error {
  @apply pl-12;
}

.form-input-with-icon-right,
.form-input-with-icon-right-error {
  @apply pr-11;
}

.form-input-right-icon {
  @apply z-0 absolute top-0 right-0 h-[45px] w-14 flex justify-center items-center;
}

.form-input-left-icon {
  @apply z-0 absolute top-0 left-0 h-[45px] w-14 flex justify-center items-center;
}

.form-input-message {
  @apply h-4 leading-4 text-sm text-danger-default my-1 flex justify-start gap-1 text-[12px] tracking-[0.3px];
}

.customDatePickerWidth,
.customDatePickerWidth > div.react-datepicker-wrapper,
.customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  .customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  input {
  width: 100%;
}
