.card-booking-available {
  @apply bg-white border-primary-default text-primary-default cursor-pointer duration-200 transition-all transform hover:scale-95 hover:shadow-lg;
}

.card-booking-selected {
  @apply bg-primary-100 border-primary-default text-primary-default cursor-pointer duration-200 transition-all transform hover:scale-95 hover:shadow-lg;
}

.card-booking-cart {
  @apply bg-primary-100 border-primary-default text-primary-default cursor-pointer duration-200 transition-all transform hover:scale-95 hover:shadow-lg;
}

.card-booking-approved {
  @apply bg-danger-lighter border-danger-default text-danger-default cursor-not-allowed;
}

.card-booking-closed {
  @apply bg-gray-300 border-gray-600 text-gray-600 cursor-not-allowed;
}

.card-booking-waiting_for_payment {
  @apply bg-[#F8ECE0] border-[#FED7B1] text-[#FBAD60] cursor-not-allowed;
}

.card-flag-available {
  @apply bg-white border-[1px] border-primary-default;
}

.card-flag-selected {
  @apply bg-primary-default border-[1px] border-primary-default;
}

.card-flag-cart {
  @apply bg-primary-default border-[1px] border-primary-default;
}

.card-flag-approved {
  @apply bg-danger-default border-[1px] border-danger-default;
}

.card-flag-waiting_for_payment {
  @apply bg-[#FED7B1] border-[1px] border-[#FED7B1];
}

/* new version */
.card-booking-v3-available {
  @apply bg-white !border-none cursor-pointer duration-200 transition-all transform hover:scale-95 hover:shadow-lg;
}

.card-booking-v3-selected {
  @apply bg-primary-100 border-primary-default text-primary-default cursor-pointer duration-200 transition-all transform hover:scale-95 hover:shadow-lg;
}

.card-booking-v3-cart {
  @apply bg-primary-100 border-primary-default text-primary-default cursor-pointer duration-200 transition-all transform hover:scale-95 hover:shadow-lg;
}

.card-booking-v3-approved {
  @apply bg-danger-lighter border-[#FF8383] text-[#FF8383] cursor-not-allowed;
}

.card-booking-v3-closed {
  @apply bg-gray-300 border-gray-600 text-gray-600 cursor-not-allowed;
}

.card-booking-v3-waiting_for_payment {
  @apply bg-[#F8ECE0] border-[#FBAD60] text-[#FBAD60] cursor-not-allowed;
}

.card-flag-v3-selected {
  @apply bg-primary-default border-[1px] border-primary-default;
}

.card-flag-v3-cart {
  @apply bg-primary-default border-[1px] border-primary-default;
}

.card-flag-v3-approved {
  @apply bg-danger-default border-[1px] border-danger-default;
}

.card-flag-v3-waiting_for_payment {
  @apply bg-[#FED7B1] border-[1px] border-[#FED7B1];
}

.inner-card-booking-v3 {
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.inner-card-booking {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
