.form-input-area,
.form-input-area-error {
  @apply flex min-h-[5rem] w-full py-[11px] px-[18px] placeholder:text-gray-400 placeholder:tracking-[0.5px] placeholder:font-light text-[12px] text-gray-700 border-[1px] rounded-[8px] outline-none transition duration-150 ease-in-out bg-[#FCFDFE] tracking-[0.3px];
}

.form-input-area {
  @apply border-gray-300 focus:border-primary-default;
}

.form-input-area-error,
.form-input-area-error {
  @apply border-danger-default;
}

.form-input-area-right-icon {
  @apply absolute top-0 right-0 h-12 w-14 flex justify-center items-center;
}

.form-input-area-left-icon {
  @apply absolute top-0 left-0 h-12 w-14 flex justify-center items-center;
}

.form-input-area-message {
  @apply h-4 leading-4 text-sm text-danger-default my-1 flex justify-start gap-1;
}
