.checkbox-wrapper input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.checkbox-wrapper label {
  position: relative;
  cursor: pointer;
}

.checkbox-wrapper label:before {
  content: "";
  -webkit-appearance: none;
  background-color: #d9d9d9;
  height: 15px;
  width: 15px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}

.checkbox-wrapper input:checked + label:before {
  content: "";
}

.checkbox-wrapper input:checked + label:after {
  @apply border-[1px] border-primary-default;
  content: "";
  display: block;
  position: absolute;
  top: -2px;
  left: 3px;
  width: 10px;
  height: 19px;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.label-checkbox {
  @apply text-[14px] tracking-wide text-gray-500;
}
