.card-radio,
.card-radio-active {
  @apply flex justify-between cursor-pointer hover:bg-primary-250 transition-all duration-200 p-4;
}

.card-radio-active {
  @apply !border-primary-default !bg-primary-lighter cursor-default hover:shadow-none;
}

.form-input-radio {
  @apply text-primary-default focus:ring-transparent focus:ring-2 my-auto cursor-pointer;
}

.label-radio,
.label-radio-active {
  @apply w-full h-full font-medium text-gray-900 cursor-pointer text-sm;
}

.label-radio-active {
  @apply font-bold text-white cursor-default;
}
