@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply tracking-[0.3px];
  font-family: "IBM Plex Sans", sans-serif;
}

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #37a240;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #da0919;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;

  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;

  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #da0919
  );
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

.layout-sidebar {
  @apply bg-fixed fixed left-0 overflow-y-auto opacity-100 bg-white h-full transition-all duration-200;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  background-image: url(./assets/images/BgSidebar.svg);
  transition: width 0.5s;
}

.topbar {
  @apply bg-white h-[68px] pl-[41px] lg:pr-[74px] pr-[41px];
  box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.12);
}

.sidebar-menu-item {
  @apply px-5 py-3 cursor-pointer hover:bg-primary-50 transition-all duration-200;
}

.sidebar-menu-item-active {
  @apply px-5 py-3 bg-primary-50 text-primary-default font-bold cursor-pointer;
}

/* Chrome, Safari, Edge, Opera */
input.number::-webkit-outer-spin-button,
input.number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"].number {
  -moz-appearance: textfield;
}

.text-nowrap {
  text-wrap: nowrap;
}
