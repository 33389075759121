.status-cards-active,
.status-cards {
  border-radius: 10px;
  height: 162px;
}

.status-cards {
  @apply hover:scale-95 hover:shadow-xl cursor-pointer transition-all duration-200 !text-dark-default;
  border: 1px solid #7269ff;
  background-color: #dbdaeb;
  background-image: url(../../assets/images/BgStatusCards.svg);
}

.status-cards-active {
  @apply text-white;
  background: linear-gradient(232deg, rgba(0, 74, 47, 0.44) 0%, #004a2f 62.5%);
}
